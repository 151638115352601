import React from "react"

export default function Home() {
  return (
    <>
        <title>DevRev Demo</title>
        
    <div>
    
    <iframe title="DevRev Demo" src="https://app.storylane.io/demo/3tz6e5wivqxe?embed=true" style={{ position:"absolute",top:0,left:0,width:"100%",height:"100%",border:"none" }} >

                 </iframe>
    
    </div>
        
    </>
  )
}